<template>
	<div class="px-4 w-full dF fC f1 pb-4 hide-scrollbar" style="overflow-y: scroll">
		<div>
			<a-tabs size="large" default-active-key="1">
				<div slot="tabBarExtraContent" class="dF aC" style="gap: 25px">
					<div v-if="corporateInstance" class="dF aC" style="gap: 5px">
						<span>Projects:</span>
						<SelectProjects class="ml-2" />
					</div>
					<div class="dF aC" style="gap: 5px">
						<span>Print:</span>
						<a @click.prevent="downloadPDF" href="javascript:;" class="text-primary">
							Charts
						</a>
						<PDFOrientation class="ml-2" />
					</div>
					<div class="dF aC" style="gap: 5px">
						<span>Download:</span>
						<a @click.prevent="getCSV" href="javascript:;" class="text-primary">CSV</a>
					</div>

					<DatePicker @current-times-update="fetchChartData($event, 'currentData')"
						:disabled-compare-date="true" />
				</div>
				<a-tab-pane key="1" tab="Overview">
					<vue-html2pdf :show-layout="true" :float-layout="false" :enable-download="false" :preview-modal="false"
						pdf-content-width="100%" :manual-pagination="true" :html-to-pdf-options="{
							margin: pdfOrientation === 'portrait' ? [50.2, 10, 50.2, 10] : [10, 50, 5, 50],
							image: { type: 'jpeg', quality: 2 },
							enableLinks: true,
							html2canvas: { scale: 1, useCORS: true },
							jsPDF: {
								orientation: pdfOrientation
							}
						}" @beforeDownload="beforeDownload($event)" ref="html2Pdf">
						<section slot="pdf-content">
							<div v-if="!error" class="report-grid">
								<ChartCard class="html2pdf__page-break" :details="qualifiedOpportunities"
									:loading="loading" />
								<ChartCard class="html2pdf__page-break" :details="qualifiedOpportunitySource"
									:loading="loading" />
								<ChartCard class="html2pdf__page-break" :details="pipelineByStage" :loading="loading"
									@mode-change="
										pipelineByStageOption = $event
										" />
								<ChartCard class="html2pdf__page-break" :details="pipelineByOwner" :loading="loading" />
								<ChartCard class="html2pdf__page-break" :details="pipelineByCreatedDate"
									:loading="loading" />
								<ChartCard class="html2pdf__page-break" :details="lostOpportunitiesByReason"
									:loading="loading" />
								<ChartCard class="html2pdf__page-break" :details="averageSalesCycle" :loading="loading" />
								<ChartCard class="html2pdf__page-break" :details="salesRepActivity" :loading="loading" />
								<ChartCard class="html2pdf__page-break" :details="opportunityConversionRate"
									:loading="loading" />
							</div>
							<div v-else class="mt-10" style="text-align: center">
								<h4>Something went wrong. Please Try again!</h4>
								<a-button type="primary" @click="fetchChartData">Retry</a-button>
							</div>
						</section>
					</vue-html2pdf>
				</a-tab-pane>
			</a-tabs>
		</div>
	</div>
</template>

<script>
import ChartCard from "@/components/charts/chartCard";
import chartData from "./chartData";
import DatePicker from "@/components/charts/datePicker.vue";
import PDFOrientation from "@/components/charts/pdfOrientation.vue";
import SelectProjects from "@/components/charts/selectProjects.vue";
import { mapState, mapMutations } from "vuex";
import VueHtml2pdf from "vue-html2pdf";
import moment from "moment";
import { formatDate } from 'bh-mod'

export default {
	components: {
		ChartCard,
		DatePicker,
		VueHtml2pdf,
		PDFOrientation,
		SelectProjects
	},
	data: () => ({
		loading: false,
		error: false,
		pipeLineReport: {
			currentData: {},
			compareData: {},
		},
		pipelineByStageOption: "countDetails",
		chartTitles: [
			'Sales qualified opportunities',
			'Sales qualified opportunity source',
			'Pipeline by stage',
			'Pipeline by owner',
			'Pipeline by created date',
			'Lost opportunities by reason',
			'Average sales cycle length',
			'Sales rep activity',
			'Opportunity to win conversion rate',
		],
	}),

	computed: {
		...mapState([
			"currentStartDate",
			"currentEndDate",
			"compareStartDate",
			"compareEndDate",
			"records",
			"instance"
		]),

		pdfOrientation() {
			return this.$store.state.pdfOrientation
		},

		corporateInstance() {
			return (this.instance && this.instance.projectType === 'corporate' && this.instance.children.length)
		},

		stages() {
			const settings = this.$store.state.reports.allSettings;
			return settings.app && settings.app.options && settings.app.options.stages && settings.app.options.stages.list || []
		},

		qualifiedOpportunities() {
			const chartDetails = chartData.qualifiedOpportunities();
			if (this.corporateInstance) {
				chartDetails.option.chartColumn = 'stack'
			}
			return {
				...chartDetails,
				chartDetails: this.getChartDetails(
					"salesQualifiedOpportunities"
				),
			};
		},
		qualifiedOpportunitySource() {
			const chartDetails = chartData.qualifiedOpportunitySource();
			if (this.corporateInstance) {
				chartDetails.option.chartColumn = 'stack'
				delete chartDetails.option.colorBy
			}
			return {
				...chartDetails,
				chartDetails: this.getChartDetails(
					"salesQualifiedOpportunitiesSource"
				),
			};
		},
		opportunityConversionRate() {
			const chartDetails = chartData.opportunityConversionRate();
			if (this.corporateInstance) {
				chartDetails.option.chartColumn = 'stack'
			}
			const result = {
				...chartDetails,
				chartDetails: this.getChartDetails(
					"opportunityToWinConversionRate"
				),
			};

			const data =
				result.chartDetails &&
				result.chartDetails.length &&
				result.chartDetails[0].data;

			if (data && data["Deals Won"] && data["Total Opportunities"]) {
				result.totalAmount =
					(data["Deals Won"] / data["Total Opportunities"]) * 100;
			}

			return result;
		},
		pipelineByStage() {
			let chartDetails = chartData.pipelineByStage();
			chartDetails = { ...chartDetails[this.pipelineByStageOption] }
			if (this.corporateInstance) {
				chartDetails.option.chartColumn = 'stack'
			}
			if (this.stages && this.stages.length) {
				chartDetails.option.xAxis.data = this.stages.map(s => s?.name);
			}
			return {
				...chartDetails,
				chartDetails: this.getChartDetails("pipelineByStage"),
			};
		},
		pipelineByOwner() {
			const chartDetails = chartData.pipelineByOwner();
			return {
				...chartDetails,
				chartDetails: this.getChartDetails("pipelineByOwner"),
			};
		},
		pipelineByCreatedDate() {
			const chartDetails = chartData.pipelineByCreatedDate();
			if (this.corporateInstance) {
				delete chartDetails.option.stringDate
			}
			return {
				...chartDetails,
				chartDetails: this.getChartDetails("pipelineByCreatedDate"),
			};
		},
		lostOpportunitiesByReason() {
			const chartDetails = chartData.lostOpportunitiesByReason();
			if (this.corporateInstance) {
				chartDetails.option.chartColumn = 'stack'
			}
			return {
				...chartDetails,
				chartDetails: this.getChartDetails("lostOpportunitiesByReason"),
			};
		},
		averageSalesCycle() {
			const chartDetails = chartData.averageSalesCycle();
			if (this.corporateInstance) {
				chartDetails.option.chartColumn = 'stack'
				delete chartDetails.option.colorBy;
			}
			return {
				...chartDetails,
				chartDetails: this.getChartDetails("averageSalesCycle"),
			};
		},
		salesRepActivity() {
			const chartDetails = chartData.salesRepActivity();
			return {
				...chartDetails,
				chartDetails: this.getChartDetails("salesRepActivity"),
			};
		},

		filename() {
			return `Pipeline Report(${moment(this.currentStartDate).format(
				"ll"
			)} - ${moment(this.currentEndDate).format("ll")})`;
		},

		pipelineRecords() {
			let records = [...this.records.pipeline.all]
			return records.map((p) => {
				return {
					...p,
					createdDate: p.createdDate && formatDate(p.createdDate) || "",
					closeDate: p.closeDate && formatDate(p.closeDate) || "",
				}
			})
		}
	},

	created() {
		this.loading = false;
	},

	methods: {
		...mapMutations(["setCurrentDates", "setCompareDates", "setRecords"]),

		getChartDetails(chartType) {
			const chartDetails = [];
			if (this.pipeLineReport.currentData[chartType]) {
				chartDetails.push({
					startDate: this.currentStartDate,
					endDate: this.currentEndDate,
					data: this.pipeLineReport.currentData[chartType],
				});
			}

			if (this.pipeLineReport.compareData[chartType]) {
				chartDetails.push({
					startDate: this.compareStartDate,
					endDate: this.compareEndDate,
					data: this.pipeLineReport.compareData[chartType],
				});
			}
			return chartDetails;
		},

		fetchChartData(
			{
				startDate = this.currentStartDate,
				endDate = this.currentEndDate,
			},
			dateType = "currentData"
		) {
			this.error = false;
			this.loading = true;

			if (dateType === "currentData") {
				this.setCurrentDates({ startDate, endDate });
			} else {
				this.setCompareDates({ startDate, endDate });
			}

			if (!startDate && !endDate) {
				this.pipeLineReport[dateType] = {};
				this.loading = false;
				this.$forceUpdate();
				return;
			}

			this.$api
				.post(`/daily-reports/${this.$store.state.instance.id}/graph`, {
					type: "pipeline",
					start: startDate,
					end: endDate,
				})
				.then(({ data }) => {
					this.setRecords({
						records: data.records,
						type: "pipeline",
					});
					delete data.records;
					this.pipeLineReport[dateType] = data;
					this.loading = false;
				})
				.catch((error) => {
					console.error(error);
					this.error = true;
					this.loading = false;
				});
		},

		downloadPDF() {
			this.$nprogress.start();
			this.$refs.html2Pdf.generatePdf();
		},

		async beforeDownload({ html2pdf, options, pdfContent }) {
			options.filename = this.filename;
			await html2pdf()
				.set(options)
				.from(pdfContent)
				.toPdf()
				.get("pdf")
				.then((pdf) => {
					const totalPages = pdf.internal.getNumberOfPages();
					for (let i = 1; i <= totalPages; i++) {
						pdf.setPage(i);
						pdf.setFontSize(11);
						pdf.text(
							"Page " + i,
							pdf.internal.pageSize.getWidth() * 0.88,
							10
						);

						pdf.setFontSize(16);

						const title = this.chartTitles[i - 1] || ''
						pdf.text(
							title,
							(pdf.internal.pageSize.getWidth() * 0.44) - title.length,
							(this.pdfOrientation === 'portrait' ? 25 : 10)
						);
					}
				})
				.save()
				.then(() => {
					this.$nprogress.done();
				})
				.catch(() => {
					this.$nprogress.done();
				});
		},

		getCSV() {
			if (this.pipelineRecords.length) {
				let csv = Object.keys(this.pipelineRecords[0]).join(",");
				csv += "\n";

				this.pipelineRecords.forEach((row) => {
					csv += Object.values(row)
						.map((r) => {
							if (typeof r === "string") {
								return r.replaceAll(", ", " & ");
							}
							return r;
						})
						.join(",");
					csv += "\n";
				});

				const anchor = document.createElement("a");
				anchor.href =
					"data:text/csv;charset=utf-8," + encodeURIComponent(csv);
				anchor.target = "_blank";
				anchor.download = `${this.filename}.csv`;
				anchor.click();
			} else {
				this.$message.info("No records found!");
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.report-grid {
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	gap: 20px;

	@media screen and (max-width: 1279px) {
		grid-template-columns: repeat(1, minmax(0, 1fr));
	}
}

.ant-select-selection__placeholder {
	color: var(--primary);
}
</style>
